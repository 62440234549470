<template>
  <div class="wrapper">
    <PageHeader :dashboard="dashboard" :page_header="page_header"> </PageHeader>
    <div class="content-wrapper">
      <div class="content">
        <div class="screen">
          <h2>Atualizações</h2>
          <div class="text-center" v-if="busy">
            <Spin />
          </div>
          <div v-for="(item, ix) in itemsComputed" :key="ix">
            <div class="box box-solid">
              <div class="box-header">
                <h3 class="box-title">
                  <strong>Versão {{ item.version }}</strong>
                </h3>
                <br />
                <span
                  ><small class="text-muted">{{ item.date_time }}</small></span
                >
              </div>
              <div class="box-body">
                <div v-for="(subitem, subix) in item.content" :key="subix">
                  <span class="label" :class="subitem.class">
                    <Icon :name="subitem.icon" v-if="subitem.icon" />&nbsp;
                    {{ subitem.title }}
                  </span>
                  <br />
                  <br />
                  <span v-html="subitem.content"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import PublicService from "@/services/public.js";
import Icon from '@/components/icons/icon.vue';
import Spin from "@/components/spin";

export default {
  name: "Changelog",
  components: {
    PageHeader,
    Icon,
    Spin
  },
  data() {
    return {
      items: [],
      service: null,
      page_header: null,
      busy: true
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters["dashboard/property"]("dashboard") || {};
    },
    itemsComputed() {
      if (this.busy || !this?.items) return [];
      let self = this;

      return this?.items?.map(function(el) {
        let lines = el.patch_notes.split("\n");
        let notes = [];
        let new_note = {
          content: ""
        }
        let text = "";

        for (let i = 0; i < lines.length; i++) {
          text = lines[i];
          if (text.includes("<p><strong>")) {
            if (new_note.content.length > 0) {
              notes.push(new_note);
              new_note = {
                content: ""
              };
            }

            if (text.includes("Novidades")) {
              new_note.title = self.stripHtml(text).replace(":", "").toUpperCase();
              new_note.class = "bg-green";
              new_note.icon = "fa fa-plus";
            } else if (text.includes("Manutenção")) {
              new_note.title = self.stripHtml(text).replace(":", "").toUpperCase();
              new_note.class = "bg-aqua";
              new_note.icon = "fa fa-wrench";
            } else {
              new_note.title = self.stripHtml(text).replace(":", "").toUpperCase();
              new_note.class = "bg-gray";
              new_note.icon = "fa fa-dot-circle-o";
            }
          } else {
            new_note.content += text;
          }
        }

        if (new_note.content.length > 0) {
          notes.push(new_note);
        }

        return {
          version: el.version,
          date_time: self.$dt.format(el.date_time).split(' ')[0],
          content: notes
        }
      });
    }
  },
  watch: {
    dashboard(n, o) {
      if (!n) return;
      this.updatePageHeader(n?.page_header);
    }
  },
  methods: {
    updatePageHeader(page_header) {
      this.$set(this, "page_header", page_header);
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    }
  },
  mounted() {
    let self = this;
    this.service = new PublicService();
    this.service.patch_notes().then((result) => {
      self.$set(self, "items", result);
      self.$set(self, "busy", false);
    });
    // Aplicar tema personalizado
    const css_theme = window.localStorage.getItem("css_theme");
    this.$utils.applyCSSTheme(css_theme);
  }
};
</script>

<style scoped>
.overlay {
  background: none;
}
.box-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0px !important;
  }
}

@media screen {
  #app > .wrapper > .content-wrapper {
    min-height: 600px;
    padding: 10px 40px 0 40px;
    background: linear-gradient(
      180deg,
      rgba(234, 243, 242, 1) 0%,
      rgba(189, 203, 206, 1) 75%,
      rgba(236, 240, 245, 1) 95%
    );
  }

  .skin-dark #app > .wrapper > .content-wrapper {
    background: #171b1d;
  }

  .wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: initial;
    min-height: 100vh;
    background-color: #ecf0f5 !important;
  }
  .content {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .waiting {
    background-color: #ffffff;
  }
  .overlay-loading {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 80px;
    text-align: center;
    margin-top: 2%;
    color: #607d8b;
    width: 100%;
    padding: 80px;
  }
  .opaque {
    opacity: 0.5;
  }
}

@media print {
  .no-print {
    display: none;
  }

  .wrapper {
    position: relative;
    overflow: none;
    background-color: #fff !important;
  }

  #app > .wrapper > .content-wrapper {
    min-height: auto;
    padding: 0 !important;
    margin-left: 0 !important;
    background: white !important;
  }

  .skin-dark #app > .wrapper > .content-wrapper {
    background: #171b1d !important;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0 !important;
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
  }
  .waiting {
    display: none;
  }
  .overlay-loading {
    display: none;
  }
  .opaque {
    display: none;
  }
}

@media print {
  .nav-tabs-custom > ul > li.active {
    display: none;
  }
  .box.box-primary,
  .box.box-warning,
  .box.box-info {
    border-top-color: transparent;
  }
}
</style>
